import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
const TextContainer = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '655px')};

  margin: ${props => props.margin || '0 auto 60px auto'};

  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '40px auto'};
  }

  & p:first-of-type {
    margin-top: 14px;
  }

  & h2 {
    @media (max-width: 767px) {
      font-size: 27px;
    }

    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }
  }
  & a {
    color: red;
    text-decoration: none;
  }
`;
const Privacy = ({ data }) => (
  <>
    <Col col={12} md={6}>
      <TextContainer
        dangerouslySetInnerHTML={{
          __html: data.prismicPrivacyPolicy.data.text.html,
        }}
        mobileMargin={`0 auto 40px auto`}
      />
    </Col>
  </>
);
export const query = graphql`
  {
    prismicPrivacyPolicy {
      id
      data {
        text {
          html
        }
      }
    }
  }
`;

export default Privacy;
